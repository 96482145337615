.container {
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.logo {
  margin: 20px 0;
}
.mainWrapper {
  width: 100%;
  height: 100vh;
}
.tagline {
  margin: 10px 0;
  text-align: center;
}
.input {
  background-color: #fff !important;
  height: 40px !important;
  width: 100% !important;
}
.button {
  height: 40px !important;
  width: 100% !important;
  margin: 10px 0 !important;
}
.accountline {
  font-size: 15px;
}
.signInLink {
  color: #8f5ef7;

  &:hover {
    color: #8f5ef7;
  }
}

.errorText {
  color: #ff4d4f; /* Typical error color */
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 12px;
}
