.textElement {
  font-size: 16px;
  width: fit-content;
  border-radius: 8px;
}

.cardStyle {
  border: 1px solid #eaecf0;
}

.heading {
  color: #101828;
}
.restaurantName {
  font-size: 18px;
  width: fit-content;
  border-radius: 8px;
  font-weight: 700;
}

.iconText {
  font-size: 14px;
  margin-left: 8px;
  margin-right: 16px;
}

.cuisine {
  font-weight: 500;
  font-size: 14px;
}
