.wrapper {
  width: 100%;
}
.logo {
  margin: 40px 0px;
}
.menu {
  width: 200px;
  font-size: 18px;
  border: none !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.childrenWrapper {
  margin-left: 230px;
  background-color: #fff;
}
.suspenseWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.alertsCountPill {
  border-radius: 50px;
  border: 1px solid #7f56d9;
  font-size: 12px;
  padding: 5px 8px;
  color: #7f56d9;
  font-weight: 800;
  margin-left: 10px;
  width: fit-content;
  height: fit-content;
}
