.header {
  padding: 20px;
}
.href {
  font-size: 20px;
  height: 90% !important;
}
.actionIcon {
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}
.userIcon {
  border-radius: 50px;
}
.divider {
  margin: 4px 0px;
}
.actionButton {
  text-align: left;
}
.popover {
  width: 200px;
}
