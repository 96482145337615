@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.ant-typography {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}
p {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}
